import React, { useState } from "react";
import { graphql, navigate } from "gatsby";
import { isSameDay, format } from "date-fns";
import { useSpring, animated } from 'react-spring';

import Layout from "../components/layout/Layout";
import { Button, SimpleButtonSymbolHover } from "../components/Button";

import grey_small_circle from "../assets/images/grey_small_circle.svg";
import grey_small_cylinder from "../assets/images/grey_small_cylinder.svg";
import grey_small_rectangle from "../assets/images/grey_small_rectangle.svg";
import grey_small_square_rotated from "../assets/images/grey_small_square_rotated.svg";
import grey_small_transcript from "../assets/images/grey_small_transcript.svg";
import white_transparent_blockquote_start from "../assets/images/white_transparent_blockquote_start.svg";
import white_transparent_blockquote_end from "../assets/images/white_transparent_blockquote_end.svg";
import white_chevron from "../assets/images/white_chevron.svg";
import ShareButtons from "../components/ShareButtons";
import VimeoPlayer from "../components/VimeoPlayer";
import { pushToGTM, getDefinedTermPopUpItem, postFBEvent } from "../helpers";
import { PageLinkList } from "../components/PageLinkList";
import ContentHTMLWithHoverPopUps from "../components/ContentHTMLWithHoverPopUps";
import HubspotForm from "../components/HubspotForm";
import { getSrc } from "gatsby-plugin-image";

export default ({ data, pageContext, location }) => {
    const [isRegistrationOpen, setIsRegistrationOpen] = React.useState(false);
    const spring = useSpring({ paddingBottom: "50px", opacity: isRegistrationOpen ? 1 : 0, transform: isRegistrationOpen ? "translateY(0px)" : "translateY(1000px)" });

    const ctaActions = [
        {
            text: "Register",
            description: "secure your spot!"
        },
        {
            text: "Watch the webinar",
            description: "watch!"
        }
    ];
    const {
        webinarStartTime,
        webinarEndTime,
        webinarSpeakers,
        webinarFormUrl,
        webinarExternalFormUrl,
        webinarCtaText,
        webinarOverview,
        webinarLanguage,
        webinarAgendaList,
        webinarFreeText,
        webinarQuotes,
        webinarImage,
        webinarUrl,
        createdAt,
        updatedAt,
        metaTags,
        pageLinkLists,
        pageLinkListHeader
    } = data.contentfulWebinarV2;

    const agendaImages = [grey_small_circle, grey_small_cylinder, grey_small_rectangle, grey_small_square_rotated, grey_small_transcript];
    const getAgendaImage = (i) => agendaImages[i] ? agendaImages[i] : agendaImages[Math.floor(Math.random() * agendaImages.length)];
    const toggleCatalogue = () => setIsRegistrationOpen(isRegistrationOpen => !isRegistrationOpen);
    const seoData = {
        updatedAt: updatedAt,
        createdAt: createdAt,
        author: webinarSpeakers[0].name,
        webinarImage: webinarImage && webinarImage.file.url,
        url: webinarUrl,
        metaTags: metaTags,
        webinarStartTime: webinarStartTime,
        webinarEndTime: webinarEndTime
    }

    const handleSubmit = () => {
        pushToGTM({
            'event': 'frends_form_submit',
            'form_type': 'Webinar Form Submit',
            'resource_name': 'Webinar registration'
        })
        if (typeof window !== "undefined") {
            if (window.fbq != null) {
                window.fbq('track', 'CompleteRegistration')
            }
        }

        postFBEvent("CompleteRegistration", "Webinar Form")

        navigate("/thanks-for-the-registration/")
    }

    const pushWebinarVideoPlayEventToGTM = (e) => {
        pushToGTM({
            'event': 'video_play_start',
            'video_name': pageContext.title,
            'duration': e.duration,
            'playback_duration_s': e.seconds,
            'playback_duration_percent': e.percent,
        });
    }

    const pushWebinarVideoStopEventToGTM = (e) => {
        pushToGTM({
            'event': 'video_play_end',
            'video_name': pageContext.title,
            'duration': e.duration,
            'playback_duration_s': e.seconds,
            'playback_duration_percent': e.percent,
        });
    }

    const pushWebinarVideoPlayingEventToGTM = (e) => {
        pushToGTM({
            'event': 'video_playing',
            'video_name': pageContext.title,
            'duration': e.duration,
            'playback_duration_s': e.seconds,
            'playback_duration_percent': e.percent,
        });
    }

    const startTimeAsDate = new Date(webinarStartTime);
    const endTimeAsDate = new Date(webinarEndTime);
    const date = isSameDay(startTimeAsDate, endTimeAsDate) ? format(startTimeAsDate, "dd.MM") : `${format(startTimeAsDate, "dd.MM")} - ${format(endTimeAsDate, "dd.MM")}`;

    const [mobileWebinarPlayerShown, setMobileWebinarPlayerShown] = useState(false);
    const handleMobileWatchWebinarButtonClick = () => setMobileWebinarPlayerShown(cur => !cur);

    const definedTermsPopUpItems = data.allContentfulDefinedTerm.nodes.map(getDefinedTermPopUpItem);

    return (
        <Layout title={pageContext.title} pageContentId="webinar-content" isWebinar={true} transparentNavigation={true} seoData={seoData} location={location}>
            {!webinarExternalFormUrl ? (
                <div className="mobile-platform-catalogue-container is-hidden-tablet">
                    <animated.div style={spring} className="platform-menu webinar">
                        <HubspotForm
                            formId={webinarFormUrl}
                            loading={<div>Loading...</div>}
                            onSubmit={handleSubmit}
                        />
                    </animated.div>
                    <nav className="navbar mobile-platform-catalogue webinar" onClick={toggleCatalogue} role="navigation" aria-label="registration">
                        <div className="navbar-item">
                            <p className="is-white size-24" style={{ marginLeft: '40px' }}>registration</p>
                        </div>
                        <div className="navbar-item chevron">
                            <img src={white_chevron} style={{ verticalAlign: "middle", transform: isRegistrationOpen ? "rotate(0deg)" : "rotate(180deg)", transition: "all .5s ease" }} />
                        </div>
                    </nav>
                </div>
            ) : (
                <nav className="webinar-mobile-watch-container is-hidden-tablet" role="navigation" aria-label="watch the webinar">
                    <div className="webinar-mobile-watch-title">
                        <p className="is-white size-24">
                            {webinarCtaText.toLowerCase()}
                        </p>
                        <SimpleButtonSymbolHover
                            type="outlined"
                            color="white"
                            text={mobileWebinarPlayerShown ? 'Hide' : ctaActions.find((x) => x.text.toLowerCase() === webinarCtaText.toLowerCase()).description}
                            onClick={() => handleMobileWatchWebinarButtonClick()}
                        />
                    </div>

                    {mobileWebinarPlayerShown && (
                        <VimeoPlayer 
                            url={webinarFormUrl}
                            play={mobileWebinarPlayerShown}
                            onPlay={() => pushWebinarVideoPlayEventToGTM()}
                            onStop={(e) => pushWebinarVideoStopEventToGTM(e)}
                            onPlaying={(d) => pushWebinarVideoPlayingEventToGTM(d)}
                        />
                    )}
                </nav>
            )}
            <main id="webinar-content" style={{ marginTop: "-80px" }}>
                <section className="hero is-medium is-hidden-mobile" style={{ background: `url(${getSrc(data.bg)}) no-repeat center/cover` }}>
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column is-6">
                                    <h2 className="font-bold size-170 is-transparent text-stroke-white">
                                        {date}
                                    </h2>
                                </div>
                                <div className="column is-6">
                                    <h1 className="size-50 is-white">
                                        {pageContext.title}
                                    </h1>
                                    <p className="size-28 is-purple-tertiary">
                                        {format(startTimeAsDate, "HH:mm")}-{format(endTimeAsDate, "HH:mm")} {format(startTimeAsDate, 'z')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="hero is-large is-hidden-tablet" style={{ background: `url(${getSrc(data.bg)}) no-repeat center/cover` }}>
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column is-6">
                                    <h2 className="font-bold size-170 is-transparent text-stroke-white">
                                        {date}
                                    </h2>
                                </div>
                                <div className="column is-6">
                                    <h1 className="size-50 is-white">
                                        {pageContext.title}
                                    </h1>
                                    <p className="size-28 is-purple-tertiary">
                                        {format(startTimeAsDate, "HH:mm")}-{format(endTimeAsDate, "HH:mm")} {format(startTimeAsDate, 'z')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section bg-grey">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                <h2 className="font-variable size-56 is-transparent text-stroke-black">
                                    speakers
                                </h2>
                                {webinarSpeakers.map((speaker, i) => (
                                    <div className="columns is-vcentered is-mobile is-multiline" key={i}>
                                        {speaker.linkedInPage ? (
                                            <>
                                                <div className="column is-2">
                                                    <img src={getSrc(speaker.portrait)} style={{ borderRadius: "50px" }} alt={speaker.name} width="90" height="90" />
                                                </div>
                                                <div className="column is-10">
                                                    <a href={speaker.linkedInPage} target="_blank">
                                                        <span className="is-black size-16">{speaker.name}</span><br />
                                                        <span className="is-grey-1 size-14">{speaker.title}</span>
                                                    </a>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="column is-2">
                                                    <img src={getSrc(speaker.portrait)} style={{ borderRadius: "50px" }} alt={speaker.name} width="90" height="90" />
                                                </div>
                                                <div className="column is-10">
                                                    <span className="is-black size-16">{speaker.name}</span><br />
                                                    <span className="is-grey-1 size-14">{speaker.title}</span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))}
                                <h2 className="font-variable size-56 is-transparent text-stroke-black">
                                    overview
                                </h2>
                                <ContentHTMLWithHoverPopUps
                                    contentHTML={webinarOverview.childMarkdownRemark.html} 
                                    popUpItems={definedTermsPopUpItems}
                                    className="content"
                                />
                                <ShareButtons url={location.href} title={`Check out our webinar: ${pageContext.title}`} />
                                <Button textClass="size-20" type="outlined" text="contact us" color="purple" to="/ask-us-anything" />
                            </div>
                            <div className="column is-hidden-mobile">
                                <div className="card is-white-bg sticky-card is-card-shadow" style={{ marginTop: "-140px" }}>
                                    <div className="card-content">
                                        {!webinarExternalFormUrl ? (
                                            <HubspotForm
                                                formId={webinarFormUrl}
                                                loading={<div>Loading...</div>}
                                                onSubmit={handleSubmit}
                                            />
                                        ) : (
                                            <div className="has-text-centered">
                                                <h2 className="font-variable is-transparent margin-bottom-20 text-stroke-black size-50">
                                                    {webinarCtaText.toLowerCase()}
                                                </h2>
                                                <VimeoPlayer 
                                                    url={webinarFormUrl}
                                                    onPlay={(e) => pushWebinarVideoPlayEventToGTM(e)}
                                                    onStop={(e) => pushWebinarVideoStopEventToGTM(e)}
                                                    onPlaying={(d) => pushWebinarVideoPlayingEventToGTM(d)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="container">
                        <h2 className="has-text-centered font-variable size-56 is-transparent text-stroke-black">
                            agenda
                        </h2>
                        <div className="columns padding-y-40">
                            <div className="column is-10 is-offset-1">
                                <div className="columns is-multiline is-hidden-mobile">
                                    {webinarAgendaList.map((agenda, i) => (
                                        <div className="column is-4" key={i}>
                                            <div className="columns">
                                                <div className="column is-2">
                                                    <img src={getAgendaImage(i)} alt="" />
                                                </div>
                                                <div className="column is-10">
                                                    {agenda}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="columns is-multiline is-hidden-tablet">
                                    {webinarAgendaList.map((agenda, i) => (
                                        <div className="column is-12" key={i}>
                                            <div className="columns is-mobile">
                                                <div className="column is-2">
                                                    <img src={getAgendaImage(i)} />
                                                </div>
                                                <div className="column is-10">
                                                    {agenda}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {webinarFreeText !== null && (
                                    <ContentHTMLWithHoverPopUps
                                        contentHTML={webinarFreeText.childMarkdownRemark.html} 
                                        popUpItems={definedTermsPopUpItems}
                                        className="content"
                                    />
                                )}
                                <div className="columns is-multiline">
                                    {webinarQuotes && webinarQuotes.map((quote, i) => (
                                        <div className="column is-4" key={i}>
                                            <div className="card is-purple-tertiary-bg has-text-centered" style={{ borderRadius: "75px" }}>
                                                <div className="card-content content">
                                                    <img src={white_transparent_blockquote_start} alt="" />
                                                    <p className="is-black size-16">
                                                        {quote.quote.quote}
                                                    </p>
                                                    <span className="font-medium is-black size-16"><strong>{quote.customerTitle}</strong></span><br />
                                                    <p className="font-medium is-grey-1 size-16">{quote.customerName}</p>
                                                    <img src={white_transparent_blockquote_end} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {pageLinkLists !== null && (
                    <section className={`section is-medium`}>
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-three-fifths is-offset-one-fifth">
                                    <PageLinkList lists={pageLinkLists} header={pageLinkListHeader} />
                                </div>
                            </div>
                        </div>
                    </section>
                )}
                <img height="1" width="1" style={{ display: "none" }} alt="" src="https://px.ads.linkedin.com/collect/?pid=2037017&conversionId=4025513&fmt=gif" />
            </main>
        </Layout>
    );
}

export const query = graphql`
query ($contentful_id: String!, $contentful_content_vocabulary_item_slugs: [String]) {
    contentfulWebinarV2 (contentful_id: { eq: $contentful_id }) {
        pageLinkListHeader
        pageLinkLists {
            linkList {
              ... on ContentfulWebinarV2 {
                __typename
                id
                title
                webinarUrl
                webinarFreeText {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                webinarImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulServicesSubpage {
                __typename
                id
                title
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulLanding {
                __typename
                id
                title
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulCustomerType {
                __typename
                id
                name
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(width: 400, height: 400)
                }
              }
              ... on ContentfulCustomer {
                __typename
                id
                name
                description
                slug
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulCoSellPartner {
                __typename
                id
                name
                slug
                slogan
              }
              ... on ContentfulArticle {
                __typename
                id
                title
                articleUrl
                subtitle
                heroImage {
                  gatsbyImageData(width: 400, height: 400)
                }
              }
            }
            title
          }
        metaTags
        createdAt
        updatedAt
        title
        text
        webinarUrl
        webinarStartTime
        webinarCtaText
        webinarSpeakers {
            title
            name
            linkedInPage
            portrait {
                gatsbyImageData(width: 90, quality: 100)
            }
        }
        webinarQuotes {
            customerTitle
            customerName
            quote {
                quote
            }
        }
        webinarOverview {
            childMarkdownRemark {
                html
            }
        }
        webinarLanguage
        webinarFreeText {
            childMarkdownRemark {
            html
            }
        }
        webinarFormUrl
        webinarExternalFormUrl
        webinarEndTime
        webinarAgendaList
        webinarImage {
            file {
                url
            }
        }
    }
    bg: file(relativePath: { eq: "images/purple_blurry_bg.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(width: 1920, quality: 100)
        }
    }
    allContentfulDefinedTerm(
        filter: {slug: {in: $contentful_content_vocabulary_item_slugs}, node_locale: {eq: "en-US"}}
        ) {
        nodes {
            slug
            title
            description {
                childMarkdownRemark {
                    html
                    excerpt
                }
            }
        }
    }
}
`